import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const SantoPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=santorini_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Santorini</h1>

                    <p>Vi har reist til Santorini på ferie ni år på rad, og i 2022 som blir det tiende(!) året på rad er dere
                        hjertelig velkommen til å bli med oss.
                        Vi reiser til Santorini den 13. juli og drar derifra til Ios den 18. juli.
                    </p>

                    <h2>Hvordan kommer man seg dit?</h2>
                    <p>
                        Med fly eller ferge fra Athen.
                    </p>

                    <h2>Hvor bør man bo?</h2>
                    <p>
                        Generelt så anbefaler vi å bo langs calderaen, dvs. klippekanten. Her finner du byene Fira, Imerovigli og Oia. Grunnen til det er at utenom klippekanten er Santorini lik de fleste greske øyene, bare at prisnivået er høyere. Dersom man bor på strandsiden betaler man da litt mer for å bo og leve enn på en generisk gresk øy, og så busser man eller tar taxi opp til klippesiden for utsikt, mat og drikke. Problemet da er at man ankommer sammen med cruiseturistene som har samme mål, og venndiagrammet hvor du får bra mat og drikke og utsikt er ganske smalt. Alle restaurantene med utsikt har så klart påslag for det. Vår logikk er da at det er verd å bruke de ekstra pengene på å bo på klippesiden, slik at du har utsikten fra hotellrommet og dermed ikke trenger å jage etter det når du skal spise middag. Her er smaken som baken så klart, men da vet dere bakgrunnen for hvorfor vi gjør som vi gjør.
                    </p>
                    <p>
                    Fira er «hovedstaden» og her finner du nattklubbene, shoppingmuligheter og restauranter. Hvis du liker litt liv og røre, er Fira kanskje det beste valget. Imerovigli ligger mellom Fira og Oia, og er den minste «byen» av de tre. Oia er det beste stedet å se solnedgangen fra, der finner du også en del serveringssteder og småbutikker å handle i.
                    </p>
                    <p>
                    Hvert år bor vi på Santorini Princess Luxury Spa Hotel, et lite boutiquehotell hvor de ansatte virkelig gjør deg til sin høyeste prioritet, samtidig som de klarer å ha en noenlunde uformell tone. Dette ligger nærmest Imerovigli, men litt utenfor. Annet enn gåturister langs Calderaen, en og annen bil og musikk i det fjerne på kveldstid fra bryllupsfeiringer så er det svært rolig rundt hotellet. Vi anbefaler så klart dette hotellet på det varmeste.
                    </p>



                    <h2>Hva kan man finne på?</h2>
                    <ul style={{textAlign:'left'}}>
                        <li>Nyte den spektakulære utsikten og solnedgangen</li>
                        <li>Vinsmaking</li>
                        <li>Akrotiri</li>
                        <li>Red, White & Black beach</li>
                        <li>Vandre langs Calderaen</li>
                        <li>Skaros rock</li>
                    </ul>

                    <h2>Hvor kan man spise?</h2>
                    <ul style={{textAlign:'left'}}>
                        <li>Fisk på Ammoudi beach</li>
                        <li>Solnedgang i Oia på Sunsets bar & restaurant</li>
                        <li>Metaximas – gresk taverna</li>
                        <li>Selene</li>
                    </ul>

                </>
            }
            {lang == "en" &&
                <>
                    <h1>Santorini</h1>

                    <p>
                        We have visited the island of Santorini 9 years in a row and this year you are more than welcome to join us for the 10th year.
                        We will be travelling on the 13th of July and returning on the 18th of July.

                    </p>

                    <h2>How to get there?</h2>
                    <p>
                        By airplane or ferry from Athens.
                    </p>

                    <h2>Where to live?</h2>
                    <p>
                        Generally we recommend living on the caldera-side of the island.
                    </p>




                    <h2>What to do?</h2>
                    <ul style={{textAlign:'left'}}>
                        <li>Enjoy the spectacular view and sunset</li>
                        <li>Wine tasting</li>
                        <li>Akrotiri</li>
                        <li>Red, White & Black beach</li>
                        <li>Caldera hiking</li>
                        <li>Skaros rock</li>
                    </ul>

                    <h2>Where to dine?</h2>
                    <ul style={{textAlign:'left'}}>
                        <li>Fish in Ammoudi beach</li>
                        <li>Sunset in Oia at Sunsets bar & restaurant</li>
                        <li>Metaximas – greek taverna</li>
                        <li>Selene</li>
                    </ul>
                </>
            }

        </main>
    )
}

export default SantoPage
